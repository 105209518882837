import React from "react";
import btwglogo from "../images/btwglogo.png";

const Landing = () => {
  return (
    <div className="landing">
      <center>
        <div className="img">
          {" "}
          <img src={btwglogo} height="500px" />
        </div>
        <div className="text">
          Welcome to BT Web Group Social Media Calendar
        </div>
        <div className="lowerText">
          Please contact us to get your calendar link. - hello@btwebgroup.com
        </div>
      </center>
    </div>
  );
};

export default Landing;
