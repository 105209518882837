import React from "react";

const Login = () => {
  return (
    <div className="home">
      <h2>Login Page</h2>
      <p>This is the Login page route</p>
    </div>
  );
};

export default Login;
