const firebaseConfig = {
  apiKey: "AIzaSyB-4EumUR2BE2DgRt7G3NTp2qVwuOBnv4o",
  authDomain: "social-media-calendar-84d06.firebaseapp.com",
  projectId: "social-media-calendar-84d06",
  storageBucket: "social-media-calendar-84d06.appspot.com",
  messagingSenderId: "166570680476",
  appId: "1:166570680476:web:46f83e6e01e4ccc4a6cd59",
  measurementId: "G-WJPMRV5CRE",
};

export default firebaseConfig;
